@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == 1-game {
  $char: "\E001";
}
  @if $filename == 2-games {
  $char: "\E002";
}
  @if $filename == 4-games {
  $char: "\E003";
}
  @if $filename == account-menu {
  $char: "\E004";
}
  @if $filename == account-second {
  $char: "\E005";
}
  @if $filename == account-sidenav {
  $char: "\E006";
}
  @if $filename == account {
  $char: "\E007";
}
  @if $filename == android {
  $char: "\E008";
}
  @if $filename == apple {
  $char: "\E009";
}
  @if $filename == arrow {
  $char: "\E00A";
}
  @if $filename == attention {
  $char: "\E00B";
}
  @if $filename == back {
  $char: "\E00C";
}
  @if $filename == balance-sidenav {
  $char: "\E00D";
}
  @if $filename == balance {
  $char: "\E00E";
}
  @if $filename == black-jack {
  $char: "\E00F";
}
  @if $filename == bonuses-sidenav {
  $char: "\E010";
}
  @if $filename == bonuses {
  $char: "\E011";
}
  @if $filename == burger-menu {
  $char: "\E012";
}
  @if $filename == cashout {
  $char: "\E013";
}
  @if $filename == chat {
  $char: "\E014";
}
  @if $filename == check-reg {
  $char: "\E015";
}
  @if $filename == check {
  $char: "\E016";
}
  @if $filename == close {
  $char: "\E017";
}
  @if $filename == complete {
  $char: "\E018";
}
  @if $filename == deposit-sidenav {
  $char: "\E019";
}
  @if $filename == deposit {
  $char: "\E01A";
}
  @if $filename == documents {
  $char: "\E01B";
}
  @if $filename == error {
  $char: "\E01C";
}
  @if $filename == eyes {
  $char: "\E01D";
}
  @if $filename == favorite {
  $char: "\E01E";
}
  @if $filename == favourite {
  $char: "\E01F";
}
  @if $filename == favourite_hover {
  $char: "\E020";
}
  @if $filename == filter-search {
  $char: "\E021";
}
  @if $filename == filter {
  $char: "\E022";
}
  @if $filename == filters {
  $char: "\E023";
}
  @if $filename == full-screen {
  $char: "\E024";
}
  @if $filename == fullscreen {
  $char: "\E025";
}
  @if $filename == halloween {
  $char: "\E026";
}
  @if $filename == heart {
  $char: "\E027";
}
  @if $filename == help-sidenav {
  $char: "\E028";
}
  @if $filename == help {
  $char: "\E029";
}
  @if $filename == history {
  $char: "\E02A";
}
  @if $filename == horseshoe {
  $char: "\E02B";
}
  @if $filename == hot {
  $char: "\E02C";
}
  @if $filename == icon-play-sidenav {
  $char: "\E02D";
}
  @if $filename == id {
  $char: "\E02E";
}
  @if $filename == info {
  $char: "\E02F";
}
  @if $filename == information {
  $char: "\E030";
}
  @if $filename == install-app-sidenav {
  $char: "\E031";
}
  @if $filename == install-app {
  $char: "\E032";
}
  @if $filename == ios {
  $char: "\E033";
}
  @if $filename == left {
  $char: "\E034";
}
  @if $filename == lightning {
  $char: "\E035";
}
  @if $filename == like {
  $char: "\E036";
}
  @if $filename == like_filled {
  $char: "\E037";
}
  @if $filename == limits {
  $char: "\E038";
}
  @if $filename == live-casino {
  $char: "\E039";
}
  @if $filename == location {
  $char: "\E03A";
}
  @if $filename == loyalty-sidenav {
  $char: "\E03B";
}
  @if $filename == loyalty {
  $char: "\E03C";
}
  @if $filename == mail {
  $char: "\E03D";
}
  @if $filename == next {
  $char: "\E03E";
}
  @if $filename == password {
  $char: "\E03F";
}
  @if $filename == play-menu {
  $char: "\E040";
}
  @if $filename == play-sidenav {
  $char: "\E041";
}
  @if $filename == play {
  $char: "\E042";
}
  @if $filename == pointer-down {
  $char: "\E043";
}
  @if $filename == polygon {
  $char: "\E044";
}
  @if $filename == profile {
  $char: "\E045";
}
  @if $filename == question-mark {
  $char: "\E046";
}
  @if $filename == refresh {
  $char: "\E047";
}
  @if $filename == responsible-gaming-menu {
  $char: "\E048";
}
  @if $filename == responsible-gaming {
  $char: "\E049";
}
  @if $filename == roulette {
  $char: "\E04A";
}
  @if $filename == search {
  $char: "\E04B";
}
  @if $filename == settings-sidenav {
  $char: "\E04C";
}
  @if $filename == settings {
  $char: "\E04D";
}
  @if $filename == shevron {
  $char: "\E04E";
}
  @if $filename == slot {
  $char: "\E04F";
}
  @if $filename == sowq {
  $char: "\E050";
}
  @if $filename == spinfinity {
  $char: "\E051";
}
  @if $filename == star {
  $char: "\E052";
}
  @if $filename == table-games {
  $char: "\E053";
}
  @if $filename == text-filter {
  $char: "\E054";
}
  @if $filename == text {
  $char: "\E055";
}
  @if $filename == time {
  $char: "\E056";
}
  @if $filename == tournaments-sidenav {
  $char: "\E057";
}
  @if $filename == tournaments {
  $char: "\E058";
}
  @if $filename == trash {
  $char: "\E059";
}
  @if $filename == user {
  $char: "\E05A";
}
  @if $filename == verify {
  $char: "\E05B";
}
  @if $filename == video-poker {
  $char: "\E05C";
}
  @if $filename == video {
  $char: "\E05D";
}
  @if $filename == wallet {
  $char: "\E05E";
}
  @if $filename == windows {
  $char: "\E05F";
}
  @if $filename == youtube {
  $char: "\E060";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-1-game {
  @include icon(1-game);
}
.icon-2-games {
  @include icon(2-games);
}
.icon-4-games {
  @include icon(4-games);
}
.icon-account-menu {
  @include icon(account-menu);
}
.icon-account-second {
  @include icon(account-second);
}
.icon-account-sidenav {
  @include icon(account-sidenav);
}
.icon-account {
  @include icon(account);
}
.icon-android {
  @include icon(android);
}
.icon-apple {
  @include icon(apple);
}
.icon-arrow {
  @include icon(arrow);
}
.icon-attention {
  @include icon(attention);
}
.icon-back {
  @include icon(back);
}
.icon-balance-sidenav {
  @include icon(balance-sidenav);
}
.icon-balance {
  @include icon(balance);
}
.icon-black-jack {
  @include icon(black-jack);
}
.icon-bonuses-sidenav {
  @include icon(bonuses-sidenav);
}
.icon-bonuses {
  @include icon(bonuses);
}
.icon-burger-menu {
  @include icon(burger-menu);
}
.icon-cashout {
  @include icon(cashout);
}
.icon-chat {
  @include icon(chat);
}
.icon-check-reg {
  @include icon(check-reg);
}
.icon-check {
  @include icon(check);
}
.icon-close {
  @include icon(close);
}
.icon-complete {
  @include icon(complete);
}
.icon-deposit-sidenav {
  @include icon(deposit-sidenav);
}
.icon-deposit {
  @include icon(deposit);
}
.icon-documents {
  @include icon(documents);
}
.icon-error {
  @include icon(error);
}
.icon-eyes {
  @include icon(eyes);
}
.icon-favorite {
  @include icon(favorite);
}
.icon-favourite {
  @include icon(favourite);
}
.icon-favourite_hover {
  @include icon(favourite_hover);
}
.icon-filter-search {
  @include icon(filter-search);
}
.icon-filter {
  @include icon(filter);
}
.icon-filters {
  @include icon(filters);
}
.icon-full-screen {
  @include icon(full-screen);
}
.icon-fullscreen {
  @include icon(fullscreen);
}
.icon-halloween {
  @include icon(halloween);
}
.icon-heart {
  @include icon(heart);
}
.icon-help-sidenav {
  @include icon(help-sidenav);
}
.icon-help {
  @include icon(help);
}
.icon-history {
  @include icon(history);
}
.icon-horseshoe {
  @include icon(horseshoe);
}
.icon-hot {
  @include icon(hot);
}
.icon-icon-play-sidenav {
  @include icon(icon-play-sidenav);
}
.icon-id {
  @include icon(id);
}
.icon-info {
  @include icon(info);
}
.icon-information {
  @include icon(information);
}
.icon-install-app-sidenav {
  @include icon(install-app-sidenav);
}
.icon-install-app {
  @include icon(install-app);
}
.icon-ios {
  @include icon(ios);
}
.icon-left {
  @include icon(left);
}
.icon-lightning {
  @include icon(lightning);
}
.icon-like {
  @include icon(like);
}
.icon-like_filled {
  @include icon(like_filled);
}
.icon-limits {
  @include icon(limits);
}
.icon-live-casino {
  @include icon(live-casino);
}
.icon-location {
  @include icon(location);
}
.icon-loyalty-sidenav {
  @include icon(loyalty-sidenav);
}
.icon-loyalty {
  @include icon(loyalty);
}
.icon-mail {
  @include icon(mail);
}
.icon-next {
  @include icon(next);
}
.icon-password {
  @include icon(password);
}
.icon-play-menu {
  @include icon(play-menu);
}
.icon-play-sidenav {
  @include icon(play-sidenav);
}
.icon-play {
  @include icon(play);
}
.icon-pointer-down {
  @include icon(pointer-down);
}
.icon-polygon {
  @include icon(polygon);
}
.icon-profile {
  @include icon(profile);
}
.icon-question-mark {
  @include icon(question-mark);
}
.icon-refresh {
  @include icon(refresh);
}
.icon-responsible-gaming-menu {
  @include icon(responsible-gaming-menu);
}
.icon-responsible-gaming {
  @include icon(responsible-gaming);
}
.icon-roulette {
  @include icon(roulette);
}
.icon-search {
  @include icon(search);
}
.icon-settings-sidenav {
  @include icon(settings-sidenav);
}
.icon-settings {
  @include icon(settings);
}
.icon-shevron {
  @include icon(shevron);
}
.icon-slot {
  @include icon(slot);
}
.icon-sowq {
  @include icon(sowq);
}
.icon-spinfinity {
  @include icon(spinfinity);
}
.icon-star {
  @include icon(star);
}
.icon-table-games {
  @include icon(table-games);
}
.icon-text-filter {
  @include icon(text-filter);
}
.icon-text {
  @include icon(text);
}
.icon-time {
  @include icon(time);
}
.icon-tournaments-sidenav {
  @include icon(tournaments-sidenav);
}
.icon-tournaments {
  @include icon(tournaments);
}
.icon-trash {
  @include icon(trash);
}
.icon-user {
  @include icon(user);
}
.icon-verify {
  @include icon(verify);
}
.icon-video-poker {
  @include icon(video-poker);
}
.icon-video {
  @include icon(video);
}
.icon-wallet {
  @include icon(wallet);
}
.icon-windows {
  @include icon(windows);
}
.icon-youtube {
  @include icon(youtube);
}
