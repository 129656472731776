@import "mixins";
@import "variables";


::-ms-reveal {
  display: none;
}
input {
  appearance: none
}
input::-webkit-caps-lock-indicator {
  content: none;
}
.scrollblock{
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

html {
  @include scrollbar;
  font-size: $font-size-default; /* root font size */
  font-family: $font-default;
  background-color: $color-background-light;
  color: $color-text;
  touch-action: auto;

  &.no-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: fixed;

    body {
      overflow: hidden;
      position: fixed;
      left: 0;
      right: 0;
    }

    &-ios {
      position: relative;
      overflow: hidden;
      height: 100%;
      position: fixed;

      body {
        overflow: hidden;
        position: fixed;
        left: 0;
        right: 0;
      }
    }
  }
}


body {
  min-height: 100vh;
}

a {
  text-decoration: none;
}

.text-color-secondary {
  color: $color-secondary;
}

.hide-chat {
  #chat-widget-container {
    display: none;
  }
}

[hidden] {
  display: none !important;
}

.center {
  text-align: center;
}

.link {
  color: $color-text-secondary;
  text-decoration: none;
  transition: 0.1s;
  cursor: pointer;
}

.container {
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: auto;

  @include media-below($bp-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.account-container {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
}

// BUTTONS

.btn {
  @include font(14px, 700);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
  border: 0;
  padding: 0 20px;
  color: $color-primary;
  transition: 0.1s;
  text-decoration: none;
  border-radius: 20px;
  user-select: none;

  @include media-below($bp-iphone-6-8) {
    @include font(12px, 700);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &:active {
    background-color: #fff;
  }

  &.big {
    @include font(18px, 700);
    height: 46px;
    border-radius: 46px;
    padding: 0 40px;

    @include media-below($bp-iphone-6-8) {
      @include font(14px, 700);
    }
  }

  &--circle-simple {
    width: 36px;
    height: 36px;
    background-color: $color-primary;
    border-radius: 50%;
    padding: 0;
    color: #fff;

    i {
      display: inline-flex;
    }

    &:hover {
      background-color: lighten($color-primary, 50);
    }
  }

  &--circle-default {
    width: 36px;
    height: 36px;
    background-color: #591789;
    border-radius: 50%;
    padding: 0;

    i {
      @include font(16px);
      display: inline-flex;
    }

    &:hover {
      background-color: $color-light;
      color: #fff;
    }
  }

  &--simple-primary {
    background-color: transparent;
    border: 2px solid darken($color-background-payment-logo, 5%);
    color: $color-background-light;

    &:hover {
      background-color: transparent;
      border-color: $color-primary;
      color: $color-primary;
    }
  }

  &--simple-secondary {
    background-color: transparent;
    border: 2px solid darken($color-background-payment-logo, 5%);
    color: $color-text;

    &:hover {
      background-color: transparent;
      border-color: $color-primary;
      color: $color-primary;
    }
  }

  &--filled-primary {
    background-color: $color-primary;
    color: #fff;

    @include media-above($bp-sm) {
      &:hover {
        background-color: lighten($color-primary, 10);
      }
    }

    &:active {
      background-color: $color-primary;
    }
  }

  &--primary {
    background-color: $color-primary;
    color: #fff;

    @include media-above($bp-sm) {
      &:hover {
        background-color: lighten($color-primary, 10);
      }
    }
  }

  &--secondary {
    background-color: transparent;
    border: 2px solid $color-primary;
    color: $color-text;

    &:hover {
      @include media-above($bp-sm) {
        border-color: #fff;
        background-color: transparent;
        color: #fff;
      }
    }

    &:active {
      border-color: #fff;
      background-color: transparent;
      color: #fff;
    }
  }

  &--filled-secondary {
    background-color: $color-secondary;
    color: $color-text-light;

    @include media-above($bp-sm) {
      &:hover {
        background-color: lighten($color-primary, 10);
      }
    }
  }
}

// Button with animate and shadow
.btn-animate {
  $color-button: $color-primary;
  $color-button-hover: lighten($color-primary, 10);
  $color-button-yellow: #F7DC26;
  $color-button-yellow-hover: lighten($color-button-yellow, 10);


  text-transform: uppercase;
  height: 52px;
  border-radius: 100px;
  padding: 0 30px;
  box-shadow: 0 7px 0 darken($color-button, 20);
  transition: 0.1s all;

  &--secondary{
    text-transform: uppercase;
    height: 52px;
    border-radius: 100px;
    padding: 0 30px;
    $color-button: #60218F;
    background: $color-button;
    box-shadow: 0 7px 0 darken($color-button, 5);
    transition: 0.1s all;

    &:hover {
      background-color: lighten($color-button, 10);
    }

    &:active {
      transform: translateY(7px);
      background-color: lighten($color-button, 10);
      box-shadow: 0 0 0 darken($color-button, 20);
    }
  }

  &:hover {
    background-color: $color-button-hover;
  }

  &:active {
    transform: translateY(7px);
    box-shadow: 0 0 0 darken($color-primary, 20);
  }

  &--yellow {
    background-color: $color-button-yellow;
    box-shadow:0 7px 0 darken($color-button-yellow, 20);

    &:hover {
      background-color: $color-button-yellow-hover;
    }

    &:active {
      box-shadow: 0 0 0 darken($color-button-yellow, 20);
    }
  }
}


.btn-crooked {
  align-items: flex-start;
  background-image: url(/assets/img/btn/1.png), url(/assets/img/btn/2.png), url(/assets/img/btn/3.png);
  background-size: 100% 66px, 100% 71px, 100% 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-color: transparent;
  height: 75px;
  line-height: 66px;
  padding: 0 40px;

  &:hover {
    background-color: transparent;
  }

  &:active {
    line-height: 68px;
    transform: translateY(7px);
    background-size: 100% 0, 100% 68px, 100% 68px;
    background-color: transparent;
  }
}

// FORM CONTROL

.input-container {

  .btn {
    width: 100%;
  }

  & > app-form-input {
    width: 100%;
    margin-bottom: 15px;
  }
}

.input {
  @include autofill($color-dark, $color-background);
  outline: none;
  cursor: pointer;
  background: transparent;
  color: $color-text;
  border-radius: 0;
  box-sizing: border-box;
  height: 40px;
  border: 0;
  border-bottom: 2px solid transparent;
  width: 100%;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: rgba($color-text, 0.6);
  }

  &--simple {
    @include font(14px, 700);
    transition: 0.2s;
    padding: 14px 16px 0 0;
    color: #fff;
    border-radius: 0;
    border-bottom: 4px solid #622C8F;
    height: 50px;
    caret-color: $color-light;

    &:focus {
      -webkit-appearance: none;

      & + .form-control__label {
        @include font(10px, 600);
        top: -10px;
        color: #A485D8;
      }
    }
  }

  &--filled {
    border: 2px solid transparent;
    height: 50px;
    border-radius: 5px;
    color: $color-text-light;
    background-color: #773BA5;
    padding: 0 19px;
    font-weight: 600;

    &:focus {
      background-color: lighten(#773BA5, 5);
    }

    &::placeholder {
      color: #E0BEF6;
    }
  }

  &--outline {
    border: 2px solid $color-primary;
  }
}

textarea.input--simple {
  padding-top: 10px;
  color: $color-dark;
}


.form-control {

  &--container {
    display: flex;
    justify-content: space-between;

    app-form-input {
      width: 100%;

      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
}

.checkbox {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: 26px;

  &--secondary {
    .checkbox__view {
      background-color: darken($color-background-payment-logo, 5%);

      &:after {
        width: 7px;
        height: 7px;
        background-color: $color-secondary;
        border: none;
        display: block;
        box-sizing: border-box;
        border-radius: 2px;
        position: absolute;
        content: '';
        transform: none;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }

    .checkbox__label {
      color: $color-text;
      @include font(14px, 700);
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:not(:checked) {
      & + .checkbox__view {
        &:after {
          display: none;
        }
      }
    }
  }

  &__view {
    position: absolute;
    box-sizing: border-box;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 0;
    background-color: #EFF2F2;

    &:after {
      width: 10px;
      height: 6px;
      border: 2px solid $color-dark;
      display: block;
      box-sizing: border-box;
      position: absolute;
      content: '';
      transform: translate(-50%, -50%) rotateZ(-45deg);
      transform-origin: center;
      top: 45%;
      left: 50%;
      border-top-width: 0;
      border-right-width: 0;
    }
  }

  &__label {
    @include font(12px, 400);
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    color: #fff;
    user-select: none;
    line-height: 1.5;

    @include media-below($bp-sm) {
      padding-left: 35px;
    }
  }
}

.select {
  @include font(14px, 500);
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: #000;

  @include ngError {
    .select__header {
      border-color: $color-error;
    }
  }

  &--secondary {
    .select {
      &__header {
        @include font(12px, 500);
        background-color: transparent;
        border: 4px solid $color-text-secondary;
        text-transform: uppercase;
        color: $color-text-light;
        border-radius: 40px;

        &:after {
          border-color: $color-secondary;
          top: 14px;
          width: 7px;
          height: 7px;
        }
      }
    }

    &.big {
      .select {
        &__header {
          color: #fff;
          height: 60px;
          line-height: 54px;
          padding: 0 75px 0 35px;
          border-radius: 60px;

          &:after {
            top: 23px;
            right: 26px;
          }
        }
      }
    }
  }

  &__header {
    @include font(12px);
    display: block;
    line-height: 38px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-text-light;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    padding: 0 45px 0 0;
    border-bottom: 4px solid #622C8F;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
      border-bottom: 4px solid #8730d0;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      border: 2px solid $color-text-secondary;
      border-top-width: 0;
      border-left-width: 0;
      transform: translateY(-50%) rotateZ(45deg);
      right: 20px;
      top: calc(50% - 2px);
    }
  }

  &__dropdown {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%) scale(0.9, 0);
    width: 100%;
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0.5;
    padding: 5px 0;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    transform-origin: center;
    box-shadow: 0 15px 0 -16px #000;
    z-index: 9999;

    &-container {
      @include scrollbar;
      overflow: auto;
      max-height: 180px;

      &::-webkit-scrollbar {
        background-color: #fff;
      }
    }

    &.open {
      visibility: visible;
      transform: translateY(-50%) scale(1, 1);
      opacity: 1;
      pointer-events: all;
      box-shadow: 0 15px 18px -16px #000;
    }
  }

  &__option {
    @include font(12px);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px 15px;
    transition: 0.1s;
    outline: none;

    &:hover {
      background-color: darken(#fff, 10);
    }
  }
}


.text {
  h1 {
    font-size: 18px;
    margin: 0;
  }
}


.password-toggle {

  &.active {
    color: $color-text-secondary;
  }
}

.informer {
  &__tooltip {
    position: fixed;
    padding: 15px;
    display: none;
    z-index: 1000;
    background-color: $color-primary;
    color: #fff;
    border-radius: 10px;
    @include font(14px);

    &.visible {
      display: block;
    }
  }
}
