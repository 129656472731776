
// colors
$color-primary: #37B53C;
$color-primary-action: #441E61;

$color-secondary: #662D91;
$color-secondary-action: #37B53C;

$color-tertiary: #9DF7CE;

$color-focus: #d54f80;

$color-background: #e5f7f7;
$color-background-light: #fff;
$color-background-payment-logo: #F2F3F5;
$color-background-input: #EFF2F2;
$color-background-hover-drop: #e6e6e6;

$color-light: #e5f7f7;
$color-dark: #441E61;

$color-text: #382f5c;
$color-text-light: #fff;
$color-text-secondary: #F7E528;

$color-error: #EC006F;
$color-success: #4BB543;
$color-disabled: #cccccc;

$color-primary-shadow: #3A8519;
$color-links-white-bg: #ffc231;
$color-secondary-lighter: #8545B5;
$color-pink: #C989FA;
$color-upcoming-tournament: #441E61;
$color-odd-tr: #4C226D;
$color-purple: #662D91;
$color-purple-lighter: #7938AA;

// fonts
$font-default: "Gotham Rounded";

// font size
$font-size-default: 16px;

// transitions
$transition-time-default: 0.2s;

// breakpoints
$bp-xs-ios: 340px;
$bp-iphone-6-8: 376px;
$bp-xs: 536px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-ld: 1200px;
$bp-xl: 1500px;

//custom breakpoints
$bp-sm-custom: 760px;
